import React, {useState, useEffect} from 'react';

interface DescriptionInputProps {
    value: string;
    setValue: (value: string) => void;

    title?: string;
    titleClassName?: string;
    description?: string;
    descriptionClassName?: string;
    errorMessage?: string;
    disabled?: boolean;
    placeholder?: string;
    isOptional?: boolean;
    required?: boolean;

    containerClassName?: string;
    inputClassName?: string;
}

export const DescriptionInput = ({
    value, 
    setValue,

    title,
    titleClassName,
    description='',
    descriptionClassName,
    errorMessage,
    disabled=false,
    placeholder,
    isOptional=false,
    required=false,
    
    containerClassName, 
    inputClassName,
}: DescriptionInputProps) => {

    const errorMessageDisplay = errorMessage ? '' : 'hidden'

    return (
        <div className={`flex flex-col gap-y-1 w-full ${containerClassName}`}>
            {   title &&
                <p 
                    className={`
                        ${titleClassName}
                        text-black text-sm font-medium
                    `}
                >
                    {title} {required ? <span className="text-red-500">*</span> : <></>}
                </p>
            }
            {description && (
                <p className={`text-sm text-gray-600 ${descriptionClassName}`}>
                    {description}
                </p>
            )}

            <textarea 
                name = {title}
                value = {value}
                placeholder={placeholder ? placeholder : undefined}

                disabled = {disabled}
                onChange = {(e) => setValue(e.target.value)}
                
                className={`
                    border border-gray-200 text-gray-900
                    placeholder-gray-400
                    py-2 px-3 bg-white
                    resize-none
                    text-base
                    rounded-md ${inputClassName}
                `}
            />

            {   errorMessage ? (
                <p
                    className={`text-sm ml-2 text-red-500 border-1 `}
                    >{errorMessage}
                </p>
            ) : (<></>)}
        </div>
    )
}
